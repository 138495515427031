import {
  UPDATE_TOKEN,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  GET_PROFILE,
  PROFILE_ERROR,
  SET_LOADING,
} from './types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_TOKEN:
      return {
        ...state,
        credentials: action.payload,
        isCredentials: true,
        loading: false,
      };
    case LOGIN:
      return {
        ...state,
        credentials: action.payload,
        isCredentials: true,
        loading: false,
      };
    case LOGIN_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        credentials: {},
        isAuthenticated: false,
        isCredentials: false,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        isAuthenticated: true,
        loading: false,
      };
    case PROFILE_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
        isCredentials: false,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
