import React, { useContext, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AuthContext from '../context/auth.Context';
import {
  Slide,
  Snackbar,
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontWeight: 500,
    marginTop: 24,
    marginBottom: 16,
  },
  paper: {
    padding: theme.spacing(5),
    textAlign: 'center',
  },
  input: {
    marginLeft: 0,
  },
}));

const Login = (props) => {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (authContext.isCredentials) {
      authContext.getProfile();
    }
  }, [authContext.isCredentials]);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      props.history.push('/');
    }
  }, [authContext.isAuthenticated]);

  useEffect(() => {
    if (authContext.error) {
      setMessage('Usuario y/o contraseña inválidos');
      setShowAlert(true);
    }
  }, [authContext.error]);

  function TransitionUp(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!username) {
      setMessage('Por favor, introduce un usuario válido');
      setShowAlert(true);
    }

    if (username) {
      authContext.login({ username, password });
      setUsername('');
      setPassword('');
    }
  };

  if (authContext.loading) {
    return (
      <div>
        <CircularProgress style={{ margin: 'auto', display: 'block' }} />
      </div>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  return (
    <Fragment>
      <Container maxWidth="xs">
        <Paper elevation={15} className={classes.paper}>
          <div className={classes.root}>
            <Grid container justify="center" direction="column">
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.title}>
                  Iniciar sesión
                </Typography>
                <TextField
                  fullWidth
                  label="Email"
                  name="username"
                  margin="normal"
                  className={classes.input}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Contraseña"
                  type="password"
                  name="password"
                  className={classes.input}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="mt-3 mb-4">
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    elevation={15}
                    fullWidth
                    className="mt-4"
                    onClick={onSubmit}>
                    Entrar
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Container>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleClose}
        message={message}
        TransitionComponent={TransitionUp}
      />
    </Fragment>
  );
};

Login.propTypes = {};

export default Login;
