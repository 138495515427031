export const STATE_MAP = {
  not_processed: 'not_processed',
  processed: 'processed',
  selected: 'selected',
};

export const COFIDIS_STATUS = [
  'ETI', // Estudio en proceso
  'ETA', // Preaceptado
  'ETR', // Rechazo
  'OCR', // Financiación rechazada
  'OCI', // Financiación incompleta
  'OCA', // Financiación aceptada
  'MEG', // Pase a gestión
  'ABA', // Desistido
];

export const COFIDIS_STATUS_MAP = {
  ETI: 'ETI', // Estudio en proceso
  ETA: 'ETA', // Preaceptado
  ETR: 'ETR/OCR', // Rechazo
  OCR: 'ETR/OCR', // Financiación rechazada
  OCI: 'OCI', // Financiación incompleta
  OCA: 'OCA', // Financiación aceptada
  MEG: 'MEG', // Pase a gestión
  ABA: 'ABA', // Desistido
};

export const NEMURU_STATUS_MAP = {
  status_initial: 'status_initial',
  status_expired: 'status_expired',
  status_desisted: 'status_desisted',
  status_analysing: 'status_analysing',
  status_denied: 'status_denied',
  status_lender_has_been_selected: 'status_lender_has_been_selected',
  status_contract_signed: 'status_contract_signed',
  status_docs_approved: 'status_docs_approved',
  status_active_trial: 'status_active_trial',
  status_active: 'status_active',
  status_ended: 'status_ended',
  status_cancelled: 'status_cancelled',
  status_unpaid: 'status_unpaid',
  status_cancelled_test: 'status_cancelled_test',
};

export const map_status = (incomingStatus) => {
  if (incomingStatus.includes('ETI')) {
    incomingStatus = incomingStatus.filter((status) => status != 'ETI');
    incomingStatus.push(COFIDIS_STATUS_MAP.ETI);
  }
  if (incomingStatus.includes('ETA')) {
    incomingStatus = incomingStatus.filter((status) => status != 'ETA');
    incomingStatus.push(COFIDIS_STATUS_MAP.ETA);
  }
  if (incomingStatus.includes('ETR')) {
    incomingStatus = incomingStatus.filter((status) => status != 'ETR');
    incomingStatus.push(COFIDIS_STATUS_MAP.ETR);
  }
  if (incomingStatus.includes('OCR')) {
    incomingStatus = incomingStatus.filter((status) => status != 'OCR');
    incomingStatus.push(COFIDIS_STATUS_MAP.OCR);
  }
  return incomingStatus;
};
