import {
  SET_LOADING,
  UNSET_LOADING,
  SET_LOADING_TRANSACTION,
  UNSET_LOADING_TRANSACTION,
  ADD_DOSSIER,
  ADD_DOSSIER_ERROR,
  EDIT_DOSSIER,
  EDIT_DOSSIER_ERROR,
  GET_DOSSIERS,
  GET_DOSSIERS_ERROR,
  GET_LOANS,
  GET_LOANS_ERROR,
  GET_TRANSACTIONS,
  TRANSACTIONS_ERROR,
  SET_CURRENT_TRANSACTION,
  UPDATE_TRANSACTION_STATUS,
  UPDATE_TRANSACTION_STATUS_ERROR,
  RESET_STATE,
  SET_SNACKBAR,
} from './types';
import TransactionsMapper from '../mappers/TransactionsMapper';
import LoansMapper from '../mappers/LoansMapper';

export default (state, action) => {
  switch (action.type) {
    case RESET_STATE:
      // console.log('RESET_STATE');
      return {
        transactions: [],
        currentTransaction: { payload: { id: null } },
        openSnackbar: false,
        snackbar: {
          show: false,
          text: '',
          severity: 'success',
        },
        loading: false,
        loadingTransaction: false,
        error: null,
        dossiersError: false,
      };
    case ADD_DOSSIER:
      // console.log('ADD_DOSSIER');
      return {
        ...state,
        loadingTransaction: false,
      };
    case ADD_DOSSIER_ERROR:
      // console.log('ADD_DOSSIER_ERROR');
      return {
        ...state,
        error: action.payload,
        loadingTransaction: false,
      };
    case EDIT_DOSSIER:
      // console.log('EDIT_DOSSIER');
      return {
        ...state,
        loadingTransaction: false,
      };
    case EDIT_DOSSIER_ERROR:
      // console.log('EDIT_DOSSIER_ERROR');
      return {
        ...state,
        error: action.payload,
        loadingTransaction: false,
      };
    case GET_DOSSIERS:
      // console.log('GET_DOSSIERS');
      return {
        ...state,
        dossiers: action.payload,
        dossiersError: false,
        //loading: false,
      };
    case GET_DOSSIERS_ERROR:
      // console.log('GET_DOSSIERS_ERROR');
      return {
        ...state,
        dossiersError: true,
        error: action.payload,
        loading: false,
      };
    case GET_LOANS:
      // console.log('GET_LOANS');
      return {
        ...state,
        loans: LoansMapper.hydrate(action.payload.data),
        //loading: false,
      };
    case GET_LOANS_ERROR:
      // console.log('GET_LOANS_ERROR');
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_TRANSACTIONS:
      // console.log('GET_TRANSACTIONS');
      return {
        ...state,
        transactions: TransactionsMapper.hydrate(action.payload.data),
        //loading: false,
      };
    case TRANSACTIONS_ERROR:
      // console.log('TRANSACTIONS_ERROR');
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SET_CURRENT_TRANSACTION:
      // console.log('SET_CURRENT_TRANSACTION');
      return {
        ...state,
        currentTransaction: action.payload,
      };
    case UPDATE_TRANSACTION_STATUS:
      // console.log('UPDATE_TRANSACTION_STATUS');
      return {
        ...state,
        // loadingTransaction: false,
      };
    case UPDATE_TRANSACTION_STATUS_ERROR:
      // console.log('UPDATE_TRANSACTION_STATUS_ERROR');
      return {
        ...state,
        error: action.payload,
        loadingTransaction: false,
      };
    case SET_LOADING:
      // console.log('SET_LOADING');
      return {
        ...state,
        loading: true,
      };
    case UNSET_LOADING:
      // console.log('UNSET_LOADING');
      return {
        ...state,
        loading: false,
      };
    case SET_LOADING_TRANSACTION:
      // console.log('SET_LOADING_TRANSACTION');
      return {
        ...state,
        loadingTransaction: true,
      };
    case UNSET_LOADING_TRANSACTION:
      // console.log('UNSET_LOADING_TRANSACTION');
      return {
        ...state,
        loadingTransaction: false,
      };
    case SET_SNACKBAR:
      // console.log('SET_SNACKBAR');
      return {
        ...state,
        snackbar: action.payload,
      };
    default:
      return state;
  }
};
