import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TransactionItem from './TransactionItem';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Transactions = ({
  loading,
  loadingTransaction,
  currentTransaction,
  transactions,
  handleClickOpen,
  handleSetCurrentTransaction,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <Grid container justify="center">
          {loading && (
            <CircularProgress
              style={{ height: 24, width: 24, marginTop: 100 }}
            />
          )}
          {!loading && !transactions && transactions.length == 0 && (
            <p className="center">No se ha encontrado ninguna transacción.</p>
          )}
          {transactions &&
            transactions.length > 0 &&
            transactions.map((transaction) => (
              <TransactionItem
                loadingTransaction={loadingTransaction}
                currentTransaction={currentTransaction}
                transaction={transaction}
                handleClickOpen={handleClickOpen}
                handleSetCurrentTransaction={handleSetCurrentTransaction}
                key={transaction.id}
              />
            ))}
        </Grid>
      </div>
    </Fragment>
  );
};

Transactions.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingTransaction: PropTypes.bool.isRequired,
  currentTransaction: PropTypes.object.isRequired,
  transaction: PropTypes.array,
  handleClickOpen: PropTypes.func.isRequired,
  handleSetCurrentTransaction: PropTypes.func.isRequired,
};

export default Transactions;
