import React from 'react';
import PropTypes from 'prop-types';
import TransactionStatus from './TransactionStatus';
import { truncateString, dateToString } from '../../../utils/formatters';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import {
  Link,
  Grid,
  useMediaQuery,
  Typography,
  Icon,
  Paper,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  textContainer: {
    display: 'inline-block',
    paddingRight: 45,
  },
  greyText: {
    fontSize: 12,
    color: '#424242',
  },
  icons: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingRight: 6,
    color: '#616161',
  },
  detailPaper: {
    backgroundColor: '#ffffff',
    padding: '15px 20px 15px 20px',
  },
}));

const StyledLinked = withStyles({
  root: {
    verticalAlign: 'middle',
    cursor: 'pointer',
    fontSize: '0.9rem',
    color: '#424242',
    fontWeight: 500,
  },
})(Link);

const TransactionItem = ({
  loadingTransaction,
  currentTransaction,
  transaction,
  handleClickOpen,
  handleSetCurrentTransaction,
}) => {
  const onClick = (e) => {
    handleSetCurrentTransaction(transaction);
    handleClickOpen();
  };

  const classes = useStyles();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid item xs={12} className={classes.item}>
      <Paper elevation={0} className={classes.detailPaper}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={smUp ? 11 : 12}>
            <Grid container direction="row">
              <Grid item md={7} sm={10} xs={12}>
                <Grid container direction="row" justify="space-between">
                  <Grid item sm={9} xs={10}>
                    <div className={classes.textContainer}>
                      <StyledLinked underline="none" onClick={onClick}>
                        {transaction.id}
                      </StyledLinked>
                      <br />
                      <Typography
                        variant="caption"
                        className={classes.greyText}>
                        {transaction.dossierId}
                      </Typography>
                    </div>
                  </Grid>
                  {smUp && (
                    <Grid item sm={3} xs={2}>
                      <Typography
                        variant="caption"
                        align="right"
                        style={{ display: smUp ? '' : 'block' }}
                        className={classes.greyText}>
                        {dateToString(transaction.updated_at)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {mdUp && (
                <Grid item md={5} xs={3} className={classes.details}>
                  <Grid container direction="row">
                    <Grid item xs={5}>
                      <Typography
                        variant="caption"
                        className={classes.greyText}>
                        <Icon className={classes.icons} fontSize="small">
                          store
                        </Icon>
                        {truncateString(transaction.merchant_full_name, 17)}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        variant="caption"
                        className={classes.greyText}>
                        <Icon className={classes.icons} fontSize="small">
                          account_circle
                        </Icon>
                        {truncateString(transaction.borrower_full_name, 17)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {loadingTransaction &&
          currentTransaction.id === transaction.id &&
          smUp ? (
            <CircularProgress style={{ height: 24, width: 24 }} />
          ) : (
            smUp && (
              <Grid item xs={1}>
                <Grid container justify="flex-end">
                  <TransactionStatus status={transaction.loanStatus} />
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

TransactionItem.propTypes = {
  loadingTransaction: PropTypes.bool.isRequired,
  currentTransaction: PropTypes.object.isRequired,
  transaction: PropTypes.object.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
  handleSetCurrentTransaction: PropTypes.func.isRequired,
};

export default TransactionItem;
