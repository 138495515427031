import React, { useContext, useEffect, Fragment } from 'react';
import AuthContext from '../context/auth.Context';
import { ENV_COOKIE_LOGIN_NAME } from '../../../config/vars';

const AuthLayer = () => {
  const authContext = useContext(AuthContext);
  const { updateToken } = authContext;

  useEffect(() => {
    const localStorageToken = JSON.parse(
      localStorage.getItem(ENV_COOKIE_LOGIN_NAME)
    );
    if (localStorageToken) {
      updateToken(localStorageToken);
    }
  }, []);

  return <Fragment></Fragment>;
};

export default AuthLayer;
