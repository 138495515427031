import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme, withStyles } from '@material-ui/core/styles';
import { Button, Icon, useMediaQuery } from '@material-ui/core';

const SortButton = ({ handleSort }) => {
  const theme = useTheme();
  const xsUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [order, setOrder] = useState(true);

  const handleClick = () => {
    setOrder(!order);
    handleSort(order, 'updated_at');
  };

  const StyledButton = withStyles((theme) => ({
    root: {
      fontSize: 12,
      backgroundColor: '#fff',
      height: 34.25,
      width: xsUp ? 'auto' : '100%',
      justifyContent: 'space-between',
      paddingLeft: 12,
    },
  }))(Button);

  return (
    <Fragment>
      <StyledButton
        onClick={handleClick}
        endIcon={
          order ? (
            <Icon>keyboard_arrow_down</Icon>
          ) : (
            <Icon>keyboard_arrow_up</Icon>
          )
        }>
        Modificado
      </StyledButton>
    </Fragment>
  );
};

SortButton.propTypes = {
  handleSort: PropTypes.func.isRequired,
};

export default SortButton;
