import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: 0,
    color: '#424242',
  },
}));

const SearchTransactions = ({ setSearch, handleChange }) => {
  const text = useRef('');
  const classes = useStyles();

  const onChange = (e) => {
    setSearch(text.current.value);
    handleChange(text.current.value);
  };

  return (
    <Fragment>
      <TextField
        label="Buscar una transacción"
        name="transaction"
        fullWidth
        onChange={onChange}
        inputRef={text}
        className={classes.textField}></TextField>
    </Fragment>
  );
};

SearchTransactions.propTypes = {
  setSearch: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default SearchTransactions;
