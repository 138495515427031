import React, { Fragment, useContext, useEffect, useState } from 'react';
import Transactions from '../components/Transactions';
import SearchTransactions from '../components/SearchTransactions';
import FilterTransactions from '../components/FilterTransactions';
import SortButton from '../components/SortButton';
import TransactionsContext from '../context/transactions.Context';
import EditTransactionModal from '../components/EditTransactionModal';
import CustomizedSnackbar from '../../Layout/components/CustomizedSnackbar';
import { Grid, Container, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  COFIDIS_STATUS,
  COFIDIS_STATUS_MAP,
  map_status,
} from '../context/status';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 500,
    marginTop: 24,
    marginBottom: 16,
  },
}));

const TransactionsView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const transactionsContext = useContext(TransactionsContext);
  const {
    loading,
    loadingTransaction,
    resetState,
    loans,
    getLoans,
    dossiers,
    dossiersError,
    addDossier,
    editDossier,
    getDossiers,
    transactions,
    getTransactions,
    currentTransaction,
    setCurrentTransaction,
    updateTransactionStatus,
    setLoading,
    unsetLoading,
    snackbar,
    setOpenSnackbar,
  } = transactionsContext;

  const [transactionsSelected, setTransactionsSelected] = useState([]);
  const [status, setStatus] = useState(COFIDIS_STATUS);
  const [search, setSearch] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    resetState();
    setLoading();
    getDossiers();
    getTransactions();
    getLoans();
  }, []);

  useEffect(() => {
    let dossiersFetched = dossiers;
    if (dossiersError === true) {
      dossiersFetched = [];
    }
    if (transactions && loans && dossiersFetched) {
      if (transactions.length == 0) {
        unsetLoading();
      } else {
        const transactionsSelected = transactionsSelectedBuilder(
          transactions,
          dossiersFetched,
          loans
        );
        setTransactionsSelected(transactionsSelected);
        unsetLoading();
      }
    }
  }, [transactions, dossiers, loans]);

  const transactionsSelectedBuilder = (transactions, dossiers, loans) => {
    let transactionsSelected = [];
    let dossiersFetched = [];
    if (dossiers) {
      dossiersFetched = dossiers;
    }
    if (transactions && dossiersFetched && loans) {
      for (let transaction of transactions) {
        if (transaction && !transaction.id) {
          continue;
        }
        // Dossier
        const dossier = dossiersFetched.filter((dossier) => {
          if (dossier.merchantTransactionId === transaction.id) {
            return dossier;
          }
        });
        let dossierId = null;
        if (!dossier.length == 0) {
          dossierId = dossier[0].dossierId;
        }
        transaction.dossierId = dossierId;
        // Loan
        const loan = loans.filter((loan) => {
          if (
            loan.loan_application_ids.includes(transaction.loan_application_id)
          ) {
            return loan;
          }
        });
        let loanStatus = null;
        if (!loan.length == 0) {
          loanStatus = loan[0].status;
        }
        if (
          loan[0].loan_status == 'status_analysing' &&
          transaction.lender_approval_status == 'yes'
        ) {
          loanStatus = COFIDIS_STATUS_MAP.ETA;
        }
        transaction.loanStatus = loanStatus;
        transactionsSelected.push(transaction);
      }
    }
    return transactionsSelected;
  };

  const updateTransactionsSelected = (incomingSearch, incomingStatus) => {
    const transactionsClean = transactionsSelectedBuilder(
      transactions,
      dossiers,
      loans
    );

    incomingStatus = map_status(incomingStatus);

    return transactionsClean.filter((transaction) => {
      if (
        incomingStatus.includes(transaction.loanStatus) &&
        ((transaction.id && transaction.id.includes(incomingSearch)) ||
          (transaction.dossierId &&
            transaction.dossierId.includes(incomingSearch)) ||
          transaction.merchant_full_name
            .toLowerCase()
            .includes(incomingSearch.toLowerCase()) ||
          transaction.borrower_full_name
            .toLowerCase()
            .includes(incomingSearch.toLowerCase()))
      ) {
        return transaction;
      }
    });
  };

  const handleChange = (incomingSearch) => {
    setTransactionsSelected(updateTransactionsSelected(incomingSearch, status));
  };

  const handleFilterByStatus = (incomingStatus) => {
    setTransactionsSelected(updateTransactionsSelected(search, incomingStatus));
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    setOpenSnackbar({
      show: false,
    });
  };

  const handleUpdateTransactionStatus = (body) => {
    if (currentTransaction.loanStatus == body.status) {
      setOpenSnackbar({
        show: true,
        text: 'Debe seleccionar un estado nuevo distinto al actual.',
        severity: 'error',
      });
    } else {
      updateTransactionStatus(body);
    }
  };

  const handleAddId = (body) => {
    addDossier(body);
  };

  const handleEditId = (body) => {
    editDossier(body);
  };

  const handleSetCurrentTransaction = (transaction) => {
    setCurrentTransaction(transaction);
  };

  const handleSort = (order, variable) => {
    const sorted = []
      .concat(transactionsSelected)
      .sort((a, b) =>
        order
          ? b[variable] > a[variable]
            ? -1
            : 1
          : b[variable] > a[variable]
          ? 1
          : -1
      );
    setTransactionsSelected(sorted);
  };

  return (
    <Fragment>
      <Container maxWidth="md">
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5" align="left" className={classes.title}>
              Listado de transacciones
            </Typography>
          </Grid>
          <Grid item className="mb-4">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end">
              <Grid item xs={12}>
                <SearchTransactions
                  setSearch={setSearch}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="mt-4 mb-2">
            <Grid container direction="row" justify="space-between">
              <Grid item sm={11} xs={12}>
                <Grid container>
                  {smUp && <Grid item md={5} sm={7} xs={1}></Grid>}
                  <Grid item className="mb-2" md={7} sm={5} xs={12}>
                    <SortButton handleSort={handleSort} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={1} xs={12}>
                <Grid container justify="flex-end">
                  <FilterTransactions
                    status={status}
                    setStatus={setStatus}
                    handleFilterByStatus={handleFilterByStatus}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Transactions
              loading={loading}
              loadingTransaction={loadingTransaction}
              currentTransaction={currentTransaction}
              transactions={transactionsSelected}
              handleClickOpen={handleClickOpen}
              handleSetCurrentTransaction={handleSetCurrentTransaction}
            />
          </Grid>
        </Grid>
      </Container>
      <EditTransactionModal
        loadingTransaction={loadingTransaction}
        currentTransaction={currentTransaction}
        openDialog={openDialog}
        handleClose={handleClose}
        handleUpdateTransactionStatus={handleUpdateTransactionStatus}
        handleAddId={handleAddId}
        handleEditId={handleEditId}
      />
      <CustomizedSnackbar
        snackbar={snackbar}
        handleCloseSnackbar={handleCloseSnackbar}
      />
    </Fragment>
  );
};

export default TransactionsView;
