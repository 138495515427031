import {
  NEMURU_STATUS_MAP as n,
  COFIDIS_STATUS_MAP as c,
} from '../context/status';

const StatusMapper = {
  hydrate(status) {
    switch (status) {
      case n.status_initial:
        return c.ETI;
      case n.status_analysing:
        return c.ETI;
      case n.status_denied:
        return c.OCR;
      case n.status_lender_has_been_selected:
        return c.ETA;
      case n.status_contract_signed:
        return c.OCI;
      case n.status_docs_approved:
        return c.OCA;
      case n.status_active_trial:
        return c.MEG;
      case n.status_active:
        return c.MEG;
      case n.status_ended:
        return c.MEG;
      case n.status_unpaid:
        return c.MEG;
      case n.status_desisted:
        return c.ABA;
      case n.status_expired:
        return c.ABA;
      case n.status_cancelled:
        return c.ABA;
      case n.status_cancelled_test:
        return c.ABA;
      default:
        return null;
    }
  },
};

export default StatusMapper;
