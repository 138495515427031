import LoanMapper from './LoanMapper';

const LoansMapper = {
  hydrate(loans) {
    return loans.map((loan) =>
      LoanMapper.hydrate(loan.payload)
    );
  },
};

export default LoansMapper;
