import TransactionMapper from './TransactionMapper';

const TransactionsMapper = {
  hydrate(transactions) {
    return transactions.map((transaction) =>
      TransactionMapper.hydrate(transaction)
    );
  },
};

export default TransactionsMapper;
