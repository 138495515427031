import React, { Fragment } from 'react';
import { Container, Grid } from '@material-ui/core';

const NotFound = () => {
  return (
    <Fragment>
      <Container maxWidth="sm">
        <Grid container justify="center" direction="column">
          <Grid item>
            <h1>Not found</h1>
          </Grid>
          <Grid item>
            <p>Esta ruta no existe</p>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default NotFound;
