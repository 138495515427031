export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const SET_CURRENT_TRANSACTION = 'SET_CURRENT_TRANSACTION';
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR';
export const SET_LOADING = 'SET_LOADING';
