import React, { useReducer } from 'react';
import axios from 'axios';

import { ENV_API_URL, ENV_COOKIE_LOGIN_NAME } from '../../../config/vars';

import AuthReducer from './auth.Reducer';
import AuthContext from './auth.Context';
import {
  UPDATE_TOKEN,
  LOGIN,
  LOGIN_ERROR,
  LOGOUT,
  GET_PROFILE,
  PROFILE_ERROR,
  SET_LOADING,
} from './types';

const AuthState = (props) => {
  const initialState = {
    credentials: {},
    isCredentials: false,
    profile: {},
    isAuthenticated: false,
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Update credentials
  const updateToken = async (token) => {
    setLoading();
    dispatch({
      type: UPDATE_TOKEN,
      payload: token,
    });
  };

  // Login
  const login = async (credentials) => {
    setLoading();
    try {
      const res = await axios.post(`${ENV_API_URL}auth/login/`, credentials, {
        'Content-Type': 'application/json',
      });
      dispatch({
        type: LOGIN,
        payload: res.data,
      });
      localStorage.removeItem(ENV_COOKIE_LOGIN_NAME);
      localStorage.setItem(ENV_COOKIE_LOGIN_NAME, JSON.stringify(res.data));
    } catch (err) {
      dispatch({
        type: LOGIN_ERROR,
        payload: err.response,
      });
    }
  };

  // Get Profile
  const getProfile = async () => {
    setLoading();
    try {
      const res = await axios.get(`${ENV_API_URL}financial/lender/profile/`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${state.credentials.access_token}`,
        },
      });
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PROFILE_ERROR,
        payload: err.response,
      });
    }
  };

  const logout = () => {
    dispatch({ type: LOGOUT });
    localStorage.removeItem(ENV_COOKIE_LOGIN_NAME);
  };
  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    // We will wrap our application inside this provider
    <AuthContext.Provider // Making state available to entire app
      value={{
        credentials: state.credentials,
        isCredentials: state.isCredentials,
        profile: state.profile,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        updateToken,
        login,
        logout,
        getProfile,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
