export const RESET_STATE = 'RESET_STATE';
export const ADD_DOSSIER = 'ADD_DOSSIER';
export const ADD_DOSSIER_ERROR = 'ADD_DOSSIER_ERROR';
export const EDIT_DOSSIER = 'EDIT_DOSSIER';
export const EDIT_DOSSIER_ERROR = 'EDIT_DOSSIER_ERROR';
export const GET_DOSSIERS = 'GET_DOSSIERS';
export const GET_DOSSIERS_ERROR = 'GET_DOSSIERS_ERROR';
export const GET_LOANS = 'GET_LOANS';
export const GET_LOANS_ERROR = 'GET_LOANS_ERROR';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const SET_CURRENT_TRANSACTION = 'SET_CURRENT_TRANSACTION';
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR';
export const UPDATE_TRANSACTION_STATUS = 'UPDATE_TRANSACTION_STATUS';
export const UPDATE_TRANSACTION_STATUS_ERROR = 'UPDATE_TRANSACTION_STATUS_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const UNSET_LOADING = 'UNSET_LOADING';
export const SET_LOADING_TRANSACTION = 'SET_LOADING_TRANSACTION';
export const UNSET_LOADING_TRANSACTION = 'UNSET_LOADING_TRANSACTION';
export const SET_SNACKBAR = 'SET_SNACKBAR';
