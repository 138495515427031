export function formatMoney(num, forcedDec) {
  const numIsInteger = num % 1 === 0;
  const defaultDec = numIsInteger ? 0 : 2;
  const dec = forcedDec === undefined ? defaultDec : forcedDec;
  return new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: dec,
    maximumFractionDigits: dec,
  }).format(num);
}

export function formatMoneyNum(num, forcedDec) {
  const money = formatMoney(num, forcedDec);
  return money.replace('€', '').trim();
}

export function formatPercent(num) {
  return new Intl.NumberFormat('es-ES', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num);
}

export function formatNumber(num) {
  return new Intl.NumberFormat('es-ES', {
    maximumSignificantDigits: 3,
  }).format(num);
}

export function formatSnakecaseToSpaces(text) {
  return text.replace(/_/g, ' ');
}

export function normalizeText(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function currencyFormat(num) {
  if (num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '€';
  }
  return null;
}

export function truncateString(str, num) {
  if (str) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }
  return str;
}

export function dateToString(dateText) {
  if (dateText) {
    let date = new Date(Date.parse(dateText));
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    const dateString = [year, month, day].join('-');
    return dateString;
  }
}
