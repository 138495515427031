import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { COFIDIS_STATUS_MAP as s } from '../context/status';

import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const TransactionStatus = ({ status }) => {
  const chipColor = () => {
    if (status === s.OCR || status === s.ETR || status === s.ABA) {
      return '#ff5252';
    }
    if (status === s.ETI) {
      return '#e0e0e0';
    }
    if (
      status === s.OCA ||
      status === s.OCI ||
      status === s.ETA ||
      status === s.MEG
    ) {
      return '#4dd970';
    }
  };

  const chipTextColor = () => {
    if (status === s.OCR || status === s.ETR || status === s.ABA) {
      return '#ffffff';
    }
    if (status === s.ETI) {
      return '#212121';
    }
    if (
      status === s.OCA ||
      status === s.OCI ||
      status === s.ETA ||
      status === s.MEG
    ) {
      return '#212121';
    }
  };

  const StyledChip = withStyles({
    root: {
      backgroundColor: chipColor(),
      color: chipTextColor(),
      fontSize: 12,
    },
  })(Chip);

  return (
    <Fragment>
      <StyledChip size="small" label={status} />
    </Fragment>
  );
};

TransactionStatus.propTypes = {
  status: PropTypes.string,
};

export default TransactionStatus;
