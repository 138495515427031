import StatusMapper from './StatusMapper';

const LoanMapper = {
  hydrate(loan) {
    return {
      id: loan.id,
      loan_application_ids: loan.loan_application_ids.map(
        (loanApplicationId) => {
          return loanApplicationId.value;
        }
      ),
      loan_status: loan.status,
      status: StatusMapper.hydrate(loan.status),
    };
  },
};

export default LoanMapper;
