import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { theme } from './assets/style/material-theme';

import AuthState from './modules/Auth/context/auth.State';
import TransactionsState from './modules/Transactions/context/transactions.State';
import AuthLayer from './modules/Auth/components/AuthLayer';
import AuthRoute from './modules/Auth/components/AuthRoute';
import Login from './modules/Auth/components/Login';
import Navbar from './modules/Layout/components/Navbar';
import NotFound from './modules/Layout/components/NotFound';
import TransactionsView from './modules/Transactions/views/TransactionsView';

function App() {
  return (
    <AuthState>
      <TransactionsState>
        <ThemeProvider theme={createMuiTheme(theme)}>
          <Router>
            <AuthLayer />
            <Navbar />
            <Switch>
              <AuthRoute exact path="/" component={TransactionsView} />
              <Route exact path="/login" component={Login} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </TransactionsState>
    </AuthState>
  );
}

export default App;
