const customThemeValues = {
  primary: {
    main: '#4dd970',
    contrast: '#08404d',
  },
  secondary: {
    main: '#08404d',
    contrast: '#ffffff',
  },
  dark: {
    main: '#08404d',
    contrast: '#ffffff',
  },
  borderRadius: {
    default: 14,
    button: 25,
  },
  typography: [
    'Raleway',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const globalValues = {
  spacing: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
};

export const theme = {
  palette: {
    primary: {
      main: customThemeValues.primary.main,
    },
    secondary: {
      main: customThemeValues.secondary.main,
    },
  },
  spacing: globalValues.spacing,
  typography: {
    fontFamily: customThemeValues.typography,
  },
  shape: {
    borderRadius: customThemeValues.borderRadius.default,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 960,
      lg: 1328,
      xl: 1920,
    },
  },
  overrides: {
    Mui: {
      root: {},
    },
    MuiButton: {
      root: {
        borderRadius: customThemeValues.borderRadius.button,
        border: 0,
        textTransform: 'none',
        fontSize: '16px',
      },
      sizeLarge: {
        height: '40px',
        fontSize: '18px',
      },
      sizeSmall: {
        height: '30px',
      },
      label: {
        fontWeight: 400, // ['Raleway-SemiBold', 'Raleway'],
      },
    },
    MuiFormControl: {
      root: {
        margin: globalValues.spacing[1],
      },
    },
    MuiInputBase: {
      input: {
        padding: '10px 0 11px',
      },
    },
    MuiTextField: {
      root: {
        color: customThemeValues.secondary.main,
        '&.dark-variant': {
          color: `${customThemeValues.dark.contrast} !important`,
        },
      },
    },
    MuiAvatar: {
      root: {
        '&.avatar-button': {
          color: '#fff',
          backgroundColor: '#4dd970',
          width: '18px',
          height: '18px',
        }
      }
    },
    MuiInput: {
      root: {
        color: 'inherit',
        position: 'relative',
        transition: 'border-bottom 0.2s ease',
        fontSize: '16px',
      },
      underline: {
        '&:before': {
          borderBottomWidth: '2px',
          borderBottomColor: `inherit !important`,
        },
        '&:hover:before': {
          borderBottomWidth: '2px',
          borderBottomColor: `inherit !important`,
        },
        '&$focused:after': {
          transform: 'none',
          borderBottomColor: `${customThemeValues.primary.main} !important`,
        },
      },
    },
    MuiPaper: {
      root: {
        '&.dark-variant': {
          background: customThemeValues.dark.main,
          color: customThemeValues.dark.contrast,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: 'inherit',
        fontSize: '16px',
      },
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.875)',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: 'inherit',
        },
      },
      focused: {},
    },
    MuiFormHelperText: {
      display: 'none',
    },
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.05),0px 1px 3px 0px rgba(0,0,0,0.08)',
    '0px 3px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.05),0px 1px 5px 0px rgba(0,0,0,0.08)',
    '0px 3px 3px -2px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.05),0px 1px 8px 0px rgba(0,0,0,0.08)',
    '0px 2px 4px -1px rgba(0,0,0,0.1),0px 4px 5px 0px rgba(0,0,0,0.05),0px 1px 10px 0px rgba(0,0,0,0.08)',
    '0px 3px 5px -1px rgba(0,0,0,0.1),0px 5px 8px 0px rgba(0,0,0,0.05),0px 1px 14px 0px rgba(0,0,0,0.08)',
    '0px 3px 5px -1px rgba(0,0,0,0.1),0px 6px 10px 0px rgba(0,0,0,0.05),0px 1px 18px 0px rgba(0,0,0,0.08)',
    '0px 4px 5px -2px rgba(0,0,0,0.1),0px 7px 10px 1px rgba(0,0,0,0.05),0px 2px 16px 1px rgba(0,0,0,0.08)',
    '0px 5px 5px -3px rgba(0,0,0,0.1),0px 8px 10px 1px rgba(0,0,0,0.05),0px 3px 14px 2px rgba(0,0,0,0.08)',
    '0px 5px 6px -3px rgba(0,0,0,0.1),0px 9px 12px 1px rgba(0,0,0,0.05),0px 3px 16px 2px rgba(0,0,0,0.08)',
    '0px 6px 6px -3px rgba(0,0,0,0.1),0px 10px 14px 1px rgba(0,0,0,0.05),0px 4px 18px 3px rgba(0,0,0,0.08)',
    '0px 6px 7px -4px rgba(0,0,0,0.1),0px 11px 15px 1px rgba(0,0,0,0.05),0px 4px 20px 3px rgba(0,0,0,0.08)',
    '0px 7px 8px -4px rgba(0,0,0,0.1),0px 12px 17px 2px rgba(0,0,0,0.05),0px 5px 22px 4px rgba(0,0,0,0.08)',
    '0px 7px 8px -4px rgba(0,0,0,0.1),0px 13px 19px 2px rgba(0,0,0,0.05),0px 5px 24px 4px rgba(0,0,0,0.08)',
    '0px 7px 9px -4px rgba(0,0,0,0.1),0px 14px 21px 2px rgba(0,0,0,0.05),0px 5px 26px 4px rgba(0,0,0,0.08)',
    '0px 8px 9px -5px rgba(0,0,0,0.1),0px 15px 22px 2px rgba(0,0,0,0.05),0px 6px 28px 5px rgba(0,0,0,0.08)',
    '0px 8px 10px -5px rgba(0,0,0,0.1),0px 16px 24px 2px rgba(0,0,0,0.05),0px 6px 30px 5px rgba(0,0,0,0.08)',
    '0px 8px 11px -5px rgba(0,0,0,0.1),0px 17px 26px 2px rgba(0,0,0,0.05),0px 6px 32px 5px rgba(0,0,0,0.08)',
    '0px 9px 11px -5px rgba(0,0,0,0.1),0px 18px 28px 2px rgba(0,0,0,0.05),0px 7px 34px 6px rgba(0,0,0,0.08)',
    '0px 9px 12px -6px rgba(0,0,0,0.1),0px 19px 29px 2px rgba(0,0,0,0.05),0px 7px 36px 6px rgba(0,0,0,0.08)',
    '0px 10px 13px -6px rgba(0,0,0,0.1),0px 20px 31px 3px rgba(0,0,0,0.05),0px 8px 38px 7px rgba(0,0,0,0.08)',
    '0px 10px 13px -6px rgba(0,0,0,0.1),0px 21px 33px 3px rgba(0,0,0,0.05),0px 8px 40px 7px rgba(0,0,0,0.08)',
    '0px 10px 14px -6px rgba(0,0,0,0.1),0px 22px 35px 3px rgba(0,0,0,0.05),0px 8px 42px 7px rgba(0,0,0,0.08)',
    '0px 11px 14px -7px rgba(0,0,0,0.1),0px 23px 36px 3px rgba(0,0,0,0.05),0px 9px 44px 8px rgba(0,0,0,0.08)',
    '0px 11px 15px -7px rgba(0,0,0,0.1),0px 24px 38px 3px rgba(0,0,0,0.05),0px 9px 46px 8px rgba(0,0,0,0.08)',
  ],
};
