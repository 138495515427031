const TransactionMapper = {
  hydrate(transaction) {
    return {
      created_at: transaction.created_at,
      updated_at: transaction.updated_at,
      id: transaction.lender_request_id,
      loan_application_id: transaction.id,
      lender_approval_status: transaction.lender_approval_status,
      state: transaction.state,
      borrower_full_name: transaction.borrower_full_name,
      merchant_full_name: transaction.merchant_full_name,
      loan_principal: transaction.loan_principal,
      loan_term: transaction.loan_term,
      loan_annual_interest: transaction.loan_annual_interest,
    };
  },
};

export default TransactionMapper;
