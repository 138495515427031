import React, { useReducer, useContext, useState } from 'react';
import axios from 'axios';

import { ENV_API_URL } from '../../../config/vars';

import TransactionsReducer from './transactions.Reducer';
import TransactionsContext from './transactions.Context';
import AuthContext from '../../Auth/context/auth.Context';
import {
  SET_LOADING,
  UNSET_LOADING,
  SET_LOADING_TRANSACTION,
  UNSET_LOADING_TRANSACTION,
  ADD_DOSSIER,
  ADD_DOSSIER_ERROR,
  EDIT_DOSSIER,
  EDIT_DOSSIER_ERROR,
  GET_DOSSIERS,
  GET_DOSSIERS_ERROR,
  GET_LOANS,
  GET_LOANS_ERROR,
  GET_TRANSACTIONS,
  TRANSACTIONS_ERROR,
  SET_CURRENT_TRANSACTION,
  UPDATE_TRANSACTION_STATUS,
  UPDATE_TRANSACTION_STATUS_ERROR,
  RESET_STATE,
  SET_SNACKBAR,
} from './types';

const ENV_FIREBASE_URL = 'https://us-central1-lender-cofidis.cloudfunctions.net/';

const TransactionsState = (props) => {
  const authContext = useContext(AuthContext);

  const initialState = {
    loans: null,
    dossiers: null,
    transactions: null,
    currentTransaction: { payload: { id: null } },
    loading: false,
    loadingStatus: false,
    loadingTransaction: false,
    snackbar: {
      show: false,
      text: '',
      severity: 'success',
    },
    error: null,
  };

  const [state, dispatch] = useReducer(TransactionsReducer, initialState);

  const addDossier = async (body) => {
    setLoadingTransaction();
    try {
      await axios
        .post(`${ENV_FIREBASE_URL}addDossier`, JSON.stringify(body), {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          dispatch({
            type: ADD_DOSSIER,
            payload: res.data,
          });
          getDossiers();
        });
    } catch (err) {
      dispatch({
        type: ADD_DOSSIER_ERROR,
        payload: err.response,
      });
    }
  };

  const editDossier = async (body) => {
    setLoadingTransaction();
    try {
      await axios
        .post(`${ENV_FIREBASE_URL}updateDossier`, JSON.stringify(body), {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          dispatch({
            type: EDIT_DOSSIER,
            payload: res.data,
          });
          getDossiers();
        });
    } catch (err) {
      dispatch({
        type: EDIT_DOSSIER_ERROR,
        payload: err.response,
      });
    }
  };

  const getDossiers = async () => {
    try {
      const res = await axios.get(`${ENV_FIREBASE_URL}getDossiers`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
      dispatch({
        type: GET_DOSSIERS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_DOSSIERS_ERROR,
        payload: err.response,
      });
    }
  };

  // Get Transactions
  const getLoans = async () => {
    // setLoading();
    try {
      const res = await axios.get(
        `${ENV_API_URL}financial/loan/lender/${authContext.profile.payload.id}/`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext.credentials.access_token}`,
          },
        }
      );
      dispatch({
        type: GET_LOANS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_LOANS_ERROR,
        payload: err.response,
      });
    }
  };

  // Get Transactions
  const getTransactions = async () => {
    // setLoading();
    try {
      const res = await axios.get(
        `${ENV_API_URL}financial/loan_application/lender/${authContext.profile.payload.id}/`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authContext.credentials.access_token}`,
          },
        }
      );
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TRANSACTIONS_ERROR,
        payload: err.response,
      });
    }
  };

  const updateTransactionStatus = async (body) => {
    setLoadingTransaction();
    await axios
      .post(`${ENV_API_URL}cofidis/change-status/`, JSON.stringify(body), {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authContext.credentials.access_token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: UPDATE_TRANSACTION_STATUS,
          payload: res.data,
        });
        setOpenSnackbar({
          show: true,
          text: `Nuevo estado ${body.status} cambiado correctamente. Espere por favor.`,
          severity: 'success',
        });
        getTransactions();
        getLoans();
        setTimeout(unsetLoadingTransaction, 2000);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_TRANSACTION_STATUS_ERROR,
          payload: err.response,
        });
        console.error(err);
        setOpenSnackbar({
          show: true,
          text: `No es posible pasar al nuevo estado ${body.status}.`,
          severity: 'error',
        });
      });
  };

  const setCurrentTransaction = (transaction) => {
    dispatch({
      type: SET_CURRENT_TRANSACTION,
      payload: transaction,
    });
  };

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  // Unset Loading
  const unsetLoading = () => dispatch({ type: UNSET_LOADING });

  // Set Loading transaction
  const setLoadingTransaction = () =>
    dispatch({ type: SET_LOADING_TRANSACTION });

  // Unset Loading transaction
  const unsetLoadingTransaction = () =>
    dispatch({ type: UNSET_LOADING_TRANSACTION });

  // Reset State
  const resetState = () => dispatch({ type: RESET_STATE });

  const setOpenSnackbar = ({ show, text = null, severity = null }) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: {
        show: show,
        text: text ?? state.snackbar.text,
        severity: severity ?? state.snackbar.severity,
      },
    });
  };

  return (
    // We will wrap our application inside this provider
    <TransactionsContext.Provider // Making state available to entire app
      value={{
        loans: state.loans,
        dossiers: state.dossiers,
        transactions: state.transactions,
        currentTransaction: state.currentTransaction,
        loading: state.loading,
        loadingStatus: state.loadingStatus,
        loadingTransaction: state.loadingTransaction,
        error: state.error,
        dossiersError: state.dossiersError,
        snackbar: state.snackbar,
        getLoans,
        addDossier,
        editDossier,
        getDossiers,
        getTransactions,
        setCurrentTransaction,
        updateTransactionStatus,
        resetState,
        setLoading,
        unsetLoading,
        setLoadingTransaction,
        unsetLoadingTransaction,
        setOpenSnackbar,
      }}>
      {props.children}
    </TransactionsContext.Provider>
  );
};

export default TransactionsState;
