import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const CustomizedSnackbar = ({ snackbar, handleCloseSnackbar }) => {
  const classes = useStyles();
  const { show, text, severity } = snackbar;

  return (
    <div className={classes.root}>
      <Snackbar
        open={show}
        autoHideDuration={8000}
        onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CustomizedSnackbar;

CustomizedSnackbar.propTypes = {
  snackbar: PropTypes.object.isRequired,
  handleCloseSnackbar: PropTypes.func.isRequired,
};
