import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, AppBar, Toolbar, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import nemuruLogo from '../../../assets/nemuru-logo.png';
import cofidisLogo from '../../../assets/cofidis-logo.png';

import AuthContext from '../../Auth/context/auth.Context';

const useStyles = makeStyles((theme) => ({
  appbar: {
    boxShadow: 'none',
  },
  buttonLink: {
    textDecoration: 'none',
  },
  button: { width: '100%' },
  nemuruLogo: {
    verticalAlign: 'middle',
    margin: '15px 30px 10px 0',
    width: 100,
  },
  cofidisLogo: {
    verticalAlign: 'middle',
    margin: '15px 0px 15px 15px',
    width: 100,
  },
  root: {
    flexGrow: 1,
  },
}));

export default function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const authContext = useContext(AuthContext);
  const { isAuthenticated, logout } = authContext;
  return (
    <Fragment>
      <div className={classes.root}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.appbar}>
          <Toolbar>
            {smUp && (
              <img
                src={nemuruLogo}
                alt="Nemuru logo"
                className={classes.nemuruLogo}
              />
            )}
            <div className={classes.root}></div>
            {isAuthenticated && (
              <Link
                to="#"
                onClick={logout}
                className={classes.buttonLink}
                style={{ width: smUp ? 150 : '100%' }}>
                <Button className={classes.button}>Cerrar sesión</Button>
              </Link>
            )}
            {smUp && (
              <img
                src={cofidisLogo}
                alt="Cofidis logo"
                className={classes.cofidisLogo}
              />
            )}
          </Toolbar>
        </AppBar>
      </div>
      <div className="mt-5"></div>
    </Fragment>
  );
}
