import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { COFIDIS_STATUS } from '../context/status';

import { withStyles, useTheme, makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputBase,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  select: { fontSize: 12 },
}));

const FilterTransactions = ({ status, setStatus, handleFilterByStatus }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = (event) => {
    setStatus(event.target.value);
    handleFilterByStatus(event.target.value);
  };

  const StyledInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 25,
      position: 'relative',
      backgroundColor: '#fff',
      fontSize: 12,
      padding: '10px 26px 10px 12px',
    },
  }))(InputBase);

  return (
    <Fragment>
      <FormControl
        style={{
          width: '100%',
          margin: smUp ? '0px 0px 0px 20px' : '0px 0px 0px 0px',
          minWidth: 120,
        }}>
        <Select
          IconComponent={KeyboardArrowDownIcon}
          className={classes.select}
          id="checkbox-status"
          multiple
          value={status}
          onChange={handleChange}
          input={<StyledInput />}
          renderValue={(selected) => selected.join(', ')}>
          {COFIDIS_STATUS.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={status.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  );
};

FilterTransactions.propTypes = {
  status: PropTypes.array.isRequired,
  setStatus: PropTypes.func.isRequired,
  handleFilterByStatus: PropTypes.func.isRequired,
};

export default FilterTransactions;
